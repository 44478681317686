import * as React from "react"
import styled from "styled-components"

import { Colors } from "../../utils/constants/colors.constants"

export const Text: React.FC = ({ children }) => (
  <Content>{children}</Content>
)

const Content = styled.p`
    font-style: normal;
    font-weight: 600;
    font-size: 1.1em;
    color: ${Colors.white};
`
