import * as React from "react"
import styled from "styled-components"

import { Colors } from "../../utils/constants/colors.constants"

export const Footer: React.FC = ({ children }) => (
  <FooterWrapper>{children}</FooterWrapper>
)

const FooterWrapper = styled.footer`
    font-family: 'Rajdhani';
    font-weight: 500;
    font-size: 1em;
    line-height: 15px;
    color: ${Colors.white};
    text-align: center;
`
